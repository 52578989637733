<template>
    <div>
      <div class="row mt-4">
                <div class="col-md-6">
                    <h3 class="purple-title ml-2">Wall Mounted Grab Bars </h3>
                </div>
             </div>
             <div class="row mt-4">
                <div class="col-md-12" >
                    <p>
                        Select a grab bar length and direction from the dropdown list below. Enter the location and grip
                        type in the “Details” section. <br>Select “Add Grab Bar” to add to the list.              
                    </p>
                </div>
             </div>
             <div class="row mt-4 ml-3">
                <div class="col-md-4 col-lg-3">
                    <label>Length (inches): <span class="text-danger">*</span></label>
                    <p class="text-danger" v-if="showLengthErrorMessage">This field is required.</p>
                </div>
                <div class="col-md-4 col-lg-3">
                    <select @change="checkForCustom()" class="form-control" v-model="createGrabBarModel.length">
                        <option value="12">12 inch</option>
                        <option value="18">18 inch</option>
                        <option value="36">36 inch</option>
                        <option value="custom">Custom Length</option>
                    </select> 
                    <div :style="showCustomGrabBar ? {'display': ''} : {'display': 'none'}">
                        <label>
                            Enter custom length:
                        </label>
                        <input maxlength="30" class="form-control mt-2" id="lengthCustomValue" type="number" v-model="createGrabBarModel.customLength" />
                    </div>
                </div>
             </div>
      <div class="row mt-4 ml-3">
                <div class="col-md-4 col-lg-3">
                    <label>Direction: <span class="text-danger">*</span></label>
                    <p class="text-danger" v-if="showDirectionErrorMessage">This field is required.</p>
                </div>
                <div class="col-md-4 col-lg-3">
                    <select @change="checkForCustom()" class="form-control" v-model="createGrabBarModel.direction">
                        <option value="vertical">Vertical</option>
                        <option value="horizontal">Horizontal</option>
                        <option value="diagonal">Diagonal</option>
                        <option value="custom">Custom Direction</option>
                    </select>
                    <div :style="showCustomDirection ? {'display': ''} : {'display': 'none'}">
                        <label>
                            Enter custom direction:
                        </label>
                        <input maxlength="30" class="form-control mt-2" id="directionCustomValue" v-model="createGrabBarModel.customDirection" />
                    </div>
                </div>
             </div>
             <div class="row mt-4 ml-3">
                <div class="col-md-4 col-lg-3">
                    <label>Details:</label>
                </div>
                <div class="col-md-4 col-lg-3">
                  <textarea maxlength="80" v-model="createGrabBarModel.details" class="form-control"></textarea>
                </div>
             </div>
             <div class="row mt-2">
                <p class="col-md-12"><span class="text-danger">* Indicates Required Field</span></p>
             </div>
             <div class="row mt-4 ml-3 mb-4" >
                <button class="btn purple-btn btn-shadow col-lg-3 offset-lg-3" @click="saveGrabBar">Add Grab Bar</button>
             </div>

   <b-table hover responsive
      :items="roomGrabBars"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      no-provider-pagin = true
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
    
      <template #cell(summary)="row">
        <button style="margin-left:5px;margin-top:5px;" class="btn btn-danger text-white font-weight-bold" @click="displayModal(row)">Remove</button>      
      </template>

    </b-table>
            <Modal v-model="showModal" title="Delete Concern">
                <p>Are you sure you would like to delete this grab bar?  </p>
                <button @click="showModal = false" class="btn btn-outline-primary">Back</button>
                <button @click="deleteGrabBar()" style="float:right;" class="btn btn-danger">Delete</button>
            </Modal>
        <div class="vld-parent">
             <loading :active.sync="isLoading" 
        :can-cancel="false"        
        :is-full-page="fullPage"></loading>
        </div>
  </div>
</template>

<script>
 //import axios from 'axios'
 import {roomService} from '../../_services'
 import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
  export default {
    data() {
      return {
        isLoading: false,
        fullPage: true,
        grabBarToDelete: 0,
        showModal: false,
        roomGrabBars: [],
        createGrabBarModel: {
                length: 0,
                customLength: 0,
                customDirection: '',
                direction: '',
                details: ''
        },
        showCustomGrabBar: false,
        showCustomDirection: false,
        showLengthErrorMessage: false,
        showDirectionErrorMessage: false,
        fields: [
          { key: 'length', label: 'Length', sortable: true, sortDirection: 'desc' },
          { key: 'direction', label: 'Direction', sortable: true, class: 'text-center' },    
          { key: 'details', label: 'Details', sortable: true, class: 'text-center'},
          { key: 'summary', label: '' }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 20,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: []
      }
    },
    mounted() {
      roomService.getGrabBars(this.$route.params.id, this.$route.params.roomId)
            .then((response) => {
                console.log(response.data);
                this.roomGrabBars = response.data;
                for(var i = 0; i < this.roomGrabBars.length; i++){
                    this.roomGrabBars[i].length = this.roomGrabBars[i].length + " Inches"
                }
            }, (ex) => {
                console.log(ex);
            });
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    },
    methods: {
      // emit add consideration event 
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      deleteGrabBar(){
          this.isLoading = true
          var clientId = this.$route.params.id;
          var roomId = this.$route.params.roomId;

          roomService.deleteRoomGrabBar(clientId, roomId, this.grabBarToDelete)
          .then((response) => {
            console.log(response);
              this.isLoading = false;
              this.showModal = false;
              for(var i = 0; i < this.roomGrabBars.length; i++){
                console.log(this.roomGrabBars[i].id);
                console.log(this.grabBarToDelete);
                if (this.roomGrabBars[i].id == this.grabBarToDelete) {
                    this.roomGrabBars.splice(i, 1);
                }
              }
              //this.items = response.data;
              //this.$emit("set-new-items", response.data)
              this.$toast.success("Successfully deleted grab bar!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
          }, () => {
              this.isLoading = false;
              this.$toast.error('Unable to delete grab bar.', {
                  position: 'bottom-center',
                  timeout: 5000,
                  hideProgressBar: true
              })
          })
      },
      checkForCustom() {
            if(this.createGrabBarModel.length == "custom") {
                this.showCustomGrabBar = true;
            } else{
                this.createGrabBarModel.customLength = "";
                this.showCustomGrabBar = false;
            }

            if(this.createGrabBarModel.direction == "custom") {
                this.showCustomDirection = true;
            } else{
                this.createGrabBarModel.customDirection = "";
                this.showCustomDirection = false;
            }
        },
        saveGrabBar() {
            //this.$emit('set-loading', true);
            if (!this.$route.params.roomId) {
                return;
            }
            if (this.createGrabBarModel.customLength) {
                this.createGrabBarModel.length = this.createGrabBarModel.customLength;
            }
            if(this.createGrabBarModel.customDirection) {
                this.createGrabBarModel.direction = this.createGrabBarModel.customDirection;
            }
            if (!this.createGrabBarModel.length) {
                this.showLengthErrorMessage = true;
                return;
            } else {             
                this.showLengthErrorMessage = false;
            }

            if (!this.createGrabBarModel.direction) {
                this.showDirectionErrorMessage = true;
                // show message
                return;
            } else {
                this.showDirectionErrorMessage = false;
            }
            
            roomService.saveRoomGrabBar(this.$route.params.id, this.$route.params.roomId, this.createGrabBarModel)
            .then(() => {
                this.$emit('set-loading', false)
                
                this.$toast.success("Grab Bar Added Successfully!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true

                })            
                roomService.getGrabBars(this.$route.params.id, this.$route.params.roomId)
                  .then((response) => {
                      console.log(response.data);
                      this.roomGrabBars = response.data;
                  }, (ex) => {
                      console.log(ex);
                  });

            }, () => {
                 this.$emit('set-loading', false)
                 this.$toast.error("Failed to add Grab Bar.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
                    }) 
            })

            this.createGrabBarModel = {};
        },

      displayModal(grabBarToDeleteId){
          this.showModal = true;
          console.log(grabBarToDeleteId)
          this.grabBarToDelete = grabBarToDeleteId.item.id;
      }
     

    },
    components:{
        'Modal':VueModal,
        Loading
    }
  }
</script>

<style>
    
</style>